import * as Sentry from '@sentry/browser';

Sentry.init({
  environment: window.sentryEnv,
  dsn: window.sentryDsn,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  ignoreErrors: [
    /.*@webkit-masked-url.*/,
    /Can't find variable: fbq/,
    /Can't find variable: ttq/,
    /_AutofillCallbackHandler/,
    /chrome-extension/,
    /fbq is not defined/,
    /ttq is not defined/,
    /Unable to preload CSS/,
  ],
  allowUrls: [/.*localhost.*/, /.*charitywater.*/],
});

window.isMobile = window.innerWidth <= 640;
window.isTablet = window.innerWidth <= 1024 && window.innerWidth > 640;
window.isDesktop = window.innerWidth > 1024;

import 'jquery-ujs';
import '~/javascripts/behaviors.js';
import '~/javascripts/behaviors/Modal.jsx';
import '~/javascripts/builder.jsx';
import '~/javascripts/components.js';
import '~/javascripts/components/Modal.jsx';
import '~/javascripts/modal.js';
import '~/javascripts/recaptcha.js';
import Cookies from 'js-cookie';
import saveAnalyticsIDs from '~/javascripts/utils/saveAnalyticsIDs';
import trackEventsOnPageLoad from '~/javascripts/utils/trackEventsOnPageLoad';
import { configure } from 'mobx';

document.addEventListener('DOMContentLoaded', () => {
  Cookies.defaults = {
    domain: window.cookieDomain,
    secure: true,
  };
});

const handleEventTracking = async () => {
  if (!document.location.pathname.startsWith('/admin')) {
    if (!sessionStorage.getItem('ga_client_id')) {
      await saveAnalyticsIDs();
    }

    trackEventsOnPageLoad();
  }
};

if (document.readyState === 'loading') {
  // `DOMContentLoaded` has not fired yet
  document.addEventListener('DOMContentLoaded', () => {
    handleEventTracking();
  });
} else {
  // `DOMContentLoaded` has already fired
  handleEventTracking();
}

configure({
  enforceActions: 'never',
  useProxies: 'ifavailable',
});

import.meta.glob('~/javascripts/utils/*.js', {
  eager: true,
});
