const waitForGtag = (counter, successCallback, errorCallback) => {
  counter++;

  if (counter > 30) {
    errorCallback();
  } else if (window.gtag) {
    successCallback();
  } else {
    setTimeout(() => {
      waitForGtag(counter, successCallback, errorCallback);
    }, 100);
  }
};

const analyticsIDs = async (field) =>
  new Promise((resolve) => {
    const cachedId = sessionStorage.getItem(field);

    if (cachedId) {
      return resolve(cachedId);
    }

    const counter = 0;
    waitForGtag(
      counter,
      () => {
        const gaMeasurementId = 'G-SKG6MDYX1T';
        const gaFieldName = field.replace('ga_', '');

        gtag('get', gaMeasurementId, gaFieldName, (val) => {
          sessionStorage.setItem(field, val);
          resolve(val);
        });
      },
      () => {
        const error = `Error getting gtag ${field}`;
        sessionStorage.setItem(field, error);
        resolve(error);
      }
    );
  });

export default analyticsIDs;
