import analyticsIDs from '~/javascripts/utils/analyticsIDs';

const saveAnalyticsIDs = async () => {
  const clientID = await analyticsIDs('ga_client_id');

  await sendToServer({
    ga_client_id: clientID,
  });
};

const sendToServer = async (params) => {
  fetch('/api/v1/analytics_id', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
};

export default saveAnalyticsIDs;
